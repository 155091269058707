.c-table {
  margin-top: 20px;
  width: 100%;
  border-bottom: 1px solid #23789c;
  border-radius: 25px 25px 0 0;
  background-color: map-get($colors-main, $key: 'normal');
}
.c-table tbody tr {
  //border-left: 1px solid #23789c;
  //border-right: 1px solid #23789c;
}
.c-table-header {
}
.c-table-header-item {
  color: map-get($colors-normal, $key: 'light');
  padding: 10px 0;
  width: 50%;
  border: none;
}
.c-table-body-item {
  padding: 15px 15px;
  background-color: white;
}
table tr td:first-child {
  border-left: 0;
}
table tr td:last-child {
  border-right: 0;
}
table tr:last-of-type > td {
  border: 0;
}
