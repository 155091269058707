.c-overzicht-titel {
  padding: 40px 0;
  color: map-get($colors-main, $key: 'normal');
  margin: 0;
}

.c-logboek-overview-item {
  line-height: 28px;
}

.c-vangsten-details{
    width: calc(100% + 30px);
    text-align: left;
}

.c-logboek-items ion-accordion{
  display: inline-block;
    width: calc(100% - 30px);
    float: left;
}

ion-icon:before {
  content: "";
  position: absolute;
  left: 0px;
  width: 24px;
  height: 100%;
}
