.c-logboek-titel {
  margin: 20px 0;
  color: map-get($colors-main, $key: 'normal');
}
.c-vangst-div {
  display: flex;
  flex-direction: column;
  //justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.c-logboek-gevangenvis, .c-logboek-items {
  width: 100%;
  line-height: 40px;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}
// .c-vangsten-item, .c-logboek-item {
//   width: 100%;
//   text-align: left;
//   border-bottom: 1px solid map-get($colors-main, $key: 'light');
// }
.c-vangsten-item, .c-logboek-item {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid map-get($colors-main, $key: 'dark');
  padding-top: 15px;
  padding-bottom: 5px;
}
.c-vangsten-item-naam {
  //padding-bottom: 6px;

  // width: 70%;
  // box-sizing: border-box;
  // color: map-get($colors-normal, $key: 'light');
  // font-weight: bold;
  // font-size: 20px;
}
.c-vangsten-item-options {
  float: right;
  font-size: 24px;
  height: 38px;
  padding-top: 3px;
}

.c-vangsten-item-options ion-icon {
  vertical-align: middle;
  height: 100%;
}

.c-vangsten-item-details{
  line-height: 24px;
  padding-left: 15px;
  font-size: 12px;
  color: #888;
}

.c-vangsten-item-details:after{
  content: "";
  display: table;
  clear: both;
}

.c-vangsten-item-details-column{
  float: left;
  width: 50%;
}

.c-vangsten-item-foto {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 30%;
  height: 100%;
  box-sizing: border-box;
  background-color: map-get($colors-normal, $key: 'light');
  border-radius: 0 14px 14px 0;
}
.c-logboek-div-visvangst-full {
  position: relative;
  width: 100%;
  //max-width: 600px;
  margin-top: 10px;
}
.c-logboek-div-visvangst-full-close {
  position: absolute;
  top: -10px;
  right: -20px;
  width: 40px;
  height: 40px;
  background-color: transparent;
}
.c-logboek-div-visvangst {
  // border: 1px solid map-get($colors-main, $key: 'normal');
  // border-radius: 5px;
  margin: auto;
}
.c-logboek-titel-h5 {
  color: map-get($colors-main, $key: 'light');
  text-align: left;
}
.c-logboek-div-visvangst-radio {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
}
.c-logboek-div-visvangst-cm-div {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
}
.c-logboek-div-visvangst-cm {
  margin-top: 15px;
  margin-bottom: 10px;
  // margin-left: 10px;
  // margin-right: 10px;
  display: flex;
}

.c-logboek-div-visvangst-label{
  align-self: center;
}
.c-logboek-div-visvangst-radio-item {
  display: inline-block;
}

.c-logboek-div-visvangst-cm input {
  margin-left: auto;
  text-align: right;
}


.c-logboek-divider {
  border-top: 1px solid map-get($colors-main, $key: 'normal');
}
.coastbusters-help-icon
{
  font-size: 32px;
  padding: 5px;
  position: relative;
  z-index: 10;
}

.alert-css .alert-wrapper, .select-alert .alert-wrapper {
  width: 90%;
  max-width: 600px;
}

ion-progress-bar{
  --progress-background: #15465b;
  --background: #47c3dd;
  height: 22px;
}

div.progress::after{
  content: " - Remember this";
}

.hidden{
  display: none;
}

#progress-step{
    position: absolute;
    top: -19px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 14px;
}

#vangst-toevoegen-content{
  width: 100%;
    position: absolute;
}
