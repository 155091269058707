// ion-app{
//   margin-top: env(safe-area-inset-top);
//   margin-bottom: env(safe-area-inset-bottom)
// }

.container {
  text-align: center;
  padding: 0 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 100%;
  overflow-y: auto;
  transform: translateY(-50%);
  &-login {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  &-home {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 20px;
    //height: calc(100vh - 44px);
    //transform: translateY(-53.5%);
  }
  &-logboek {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
    // align-items: center;
    //height: calc(100vh - 44px);
    //transform: translateY(-53.5%);
    box-sizing: border-box;
  }
  &-loggedIn {
    //margin-top: 44px;
    padding-top: 61px;
  }
}
.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
}

.container a {
  text-decoration: none;
}

.ios .container {
  &-loggedIn {
    //margin-top: 44px;
    padding-top: 95px; // 34 pixels for notch height
  }
}
