// .c-home-nieuwLogboek-div {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
//   align-items: center;

//   height: 70%;
// }
.c-home-titel {
  margin: 0;
  color: map-get($colors-main, $key: 'normal');
}

.align-left {
  text-align: left;
}

.page-title {
  text-align: left;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: bold;
  font-size: 18px;
}
