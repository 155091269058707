.c-button {
  border-radius: 16px;
  margin-top: 40px;
  padding: 6px 0;
  width: 70%;
  max-width: 400px;
  color: map-get($colors-main, $key: 'normal');
  font-weight: bold;
  font-size: 17px;
  background-color: map-get($colors-normal, $key: 'light');
  border: 2px solid map-get($colors-main, $key: 'normal');
  outline: none;
  &:hover {
    background-color: map-get($colors-main, $key: 'normal');
    color: map-get($colors-normal, $key: 'light');
  }
  &:active {
    background-color: map-get($colors-main, $key: 'normal');
    color: map-get($colors-normal, $key: 'light');
    outline: none;
  }
}
.c-button-full {
  border-radius: 16px;
  margin-top: 40px;
  padding: 6px 0;
  width: 70%;
  max-width: 400px;
  color: map-get($colors-normal, $key: 'light');
  font-weight: bold;
  font-size: 17px;
  background-color: map-get($colors-main, $key: 'normal');
  border: 2px solid map-get($colors-main, $key: 'normal');
  outline: none;
  &:hover {
    background-color: map-get($colors-normal, $key: 'light');
    color: map-get($colors-main, $key: 'normal');
  }
  &:active {
    background-color: map-get($colors-normal, $key: 'light');
    color: map-get($colors-main, $key: 'normal');
    outline: none;
  }
}
.c-button-home {
  margin: 0;
}
.c-button-moreinfo {
  font-size: 20px;
  background-color: transparent;
  outline: none;
}
.c-button-addvis {
  margin: 20px auto;
  width: 25px;
  //height: 150px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 25px;
}
.c-button-toevoegen {
  margin: 20px auto;
}
.c-button-terug {
  background-color: transparent;
  font-size: 20px;
  color: map-get($colors-main, $key: 'normal');
  position: absolute;
  top: 60px;
  left: 10px;
}
