.c-vangst-icon {
  width: 25px;
  height: 20px;
}
.c-close-icon {
  width: 75%;
  height: 75%;
}
.c-terug-icon {
  width: 40px;
  height: 40px;
}
