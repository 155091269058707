.c-form-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.c-form-div-logboek {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
// .c-form-item {
//   display: flex;
//   flex-direction: column;
//   //justify-content: space-between;
//   align-items: flex-start;
//   width: 100%;
//   height: 60%;
//   margin-top: 12px;
//   &-logboek {
//     margin-bottom: 0px;
//   }
// }
.c-form-item {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  //height: 60%;
  margin-top: 12px;
  &-logboek {
    margin-bottom: 0px;
  }
}
.c-form-item-duur {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin-top: 12px;
}

.c-input-date{
  max-width: 500px;
}

.c-form-label {
  //color: map-get($colors-main, $key: 'normal');
  color: #333;
  font-weight: bold;
  font-size: 18px;
}
.c-form-input {
  -webkit-appearance: none;
  outline: none;
  border: none;
  width: 100%;
  padding: 6px 2px;
  background-color: transparent;
  border-bottom: 1px solid map-get($colors-main, $key: 'light');
  //border-bottom: 1px solid #333;
}
#profile-container .c-form-input[disabled]{
  background-color: #eee;
color: #aaa;
}
.c-form-input-ion {
  -webkit-appearance: none;
  outline: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 6px 2px;
  background-color: transparent;
  border-bottom: 1px solid map-get($colors-main, $key: 'light');
}
.c-form-input-duur {
  -webkit-appearance: none;
  outline: none;
  border: none;
  width: 70px;
  padding: 6px 2px;
  background-color: transparent;
  border-bottom: 1px solid map-get($colors-main, $key: 'normal');
}
.c-login-checkbox-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  margin-top: 12px;
}
input[type='checkbox'] {
  transform: scale(1.5);
}
.c-login-checkbox-label {
  padding-left: 24px;
  text-align: left;
}
.c-form-radio-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  &-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 12px;
  }
}
.c-form-radio-item-div-vertical {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 6px 0;
}
.c-form-radio-label {
  margin: 0 8px;
  &-vertical {
    margin: 0 8px;
    text-align: left;
  }
}
.c-form-select {
  //margin-top: 12px;
  padding: 6px 12px;
  //-webkit-appearance: none;
  outline: none;
  border: none;
  width: 50%;
  background-color: transparent;
  border-bottom: 1px solid map-get($colors-main, $key: 'normal');
}
.c-form-select-option {
}
.c-form-buttons-div {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  margin-top: 12px;
}

.c-form-buttons-div ion-button{
  min-width: 125px;
}
.c-form-button-whitespace {
  width: 20%;
}
.c-form-error {
  color: red;
  font-weight: bold;
}
.c-form-info {
  color: map-get($colors-main, $key: 'normal');
  width: 100%;
  font-weight: bold;
}

.container-logboek .c-form-item ion-list{
  width: 100%;
}

.progress-buffer-bar {
  background: map-get($colors-normal, $key: 'dark');
}

.logboek-column{
  float: left;
  width: 50%;
}

.logboek-row {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logboek-row:after {
  content: "";
  display: table;
  clear: both;
}

.column-label{
  line-height: 43px;
  text-align: left;
  align-self: center;
}

.input-right, .input-right input{
  text-align: right;
  width: 130px;
  float: right;
}

.input-right-long, .input-right input{
  width: 260px;
}

.input-small, .input-small input{
  width: 90px;
}

.div-left{
  text-align: left;
}

.padding-left-0{
  padding-left: 0px;
}

.padding-top-10{
  padding-top: 10px;
}

.float-left{
  float: left;
}

.full-width{
  width: 100%;
}

.margin-bottom-10{
  margin-bottom: 10px;
}

.margin-bottom-20{
  margin-bottom: 20px;
}

.margin-top-35{
  margin-top: 35px;
}

.margin-top-12{
  margin-top: 12px;
}



.container-logboek .c-form-item ion-list.margin-left-minus-20{
  margin-left: -20px;
  width: calc(100% + 20px);
}

#account-edit{
  padding: 15px;
  border: 1px solid map-get($colors-main, $key: 'light');
  border-radius: 10px;
  margin-bottom: 10px;
}