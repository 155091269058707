.c-pop-over {
}
.c-pop-over-item {
  background-color: white;
  width: 100%;
  height: 50%;
  padding: 15px 0;
  font-size: 17px;
  &:not(:last-of-type) {
    border-bottom: 1px solid map-get($colors-main, $key: 'normal');
  }
  :hover {
    background-color: grey;
  }
  :active {
    background-color: grey;
  }
}
.c-button-moreinfo {
  padding: 0;
  width: 6px;
}
.c-pop-up-selection {
  height: 100%;
  width: 100%;
  fill: map-get($colors-main, $key: 'normal');
}
