.c-login-logo {
  margin: 0;
  width: 100%;
  max-width: 500px;
}

.c-login-logo-registreren {
  margin: 40px 0 40px 0;
  width: 75%;
  max-width: 500px;
}

.c-login-geen_acc {
  margin: 12px 0;
  color: map-get($colors-main, $key: 'dark');
  font-weight: bold;
}

.c-login-terug {
  width: 100px;
  border-radius: 7px;
  margin: 0;
  &:hover a {
    color: map-get($colors-main, $key: 'light');
  }
  &:active a {
    color: map-get($colors-main, $key: 'light');
  }
  & a {
    color: map-get($colors-main, $key: 'normal');
    font-size: 15px;
  }
}
.c-spinner {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.c-spinner-hide {
  display: none;
}

.login-btn-div, .register-btn-div{
    align-items: center !important;
    margin-bottom: 15px;
}

.primary-underline{
  color: map-get($colors-main, $key: 'light');
  text-decoration: underline;
}

.no-decoration{
  text-decoration: none;
}

.margin-top-15{
  margin-top: 15px;
}
.margin-bottom-15{
  margin-bottom: 15px;
}

#register-form-content{
  width: 100%;
}

label.required:after{
  content: ' *';
  color: red;
}

.form-cb-left{
  margin: 20px 0px;
  text-align: left;
}
